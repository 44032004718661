// =========================================/
// Global Admin
// =========================================/

.page-admin {
	background-color: $admin-color-bg-light;
	padding: 30px 0px;
	h1,h2,h3,h4,h5,h6 {
		font-family: $admin-header-font;
	}
	p,li,input,select,textarea {
		font-family: $admin-body-font;
	}
	a {
		color: $admin-color-primary;
		&:hover {
			color: $admin-color-primary;
			text-decoration: underline;
		}
	}
	label {
		font-size: 14px;
		font-weight: 900;
	}
	.breadcrumbs {
		.bc-active {
			margin: 0px;
		}
	}
	.pa-container {
		@include global-width;
	}
}

.admin-no-results {
	border-top: 1px dashed $admin-color-bg-medium;
	border-bottom: 1px dashed $admin-color-bg-medium;
	text-align: center;
	padding: 30px;
}
.show-input {
	display: block !important;
	width: 100%;
  padding: 8px;
  border: 1px solid $admin-color-bg-medium;
  margin: 5px 0px 10px;
}
.wrong-password {
	span {
		cursor: pointer;
		color: $color-primary;
		&:hover {
			text-decoration: underline;
		}
	}
}

// =========================================/
// Block
// =========================================/

.block {
	background-color: #fff;
	box-shadow: 0px 2px 2px #d3d3d3;
	padding: 40px;
	border-radius: 5px;
	margin-bottom: 50px;
	h1 {
		font-size: 1.5em;
		margin-bottom: 1em;
	}
	p {
		margin-bottom: 1.5em;
	}
	h3 {
		font-size: 1.3em;
		margin-bottom: 1.5em;
	}
	.tabs {
		@include flex-container(stretch,center);
		p {
			margin: 0px;
			padding: 10px 20px;
			border-radius: 3px;
			cursor: pointer;
			flex-grow: 1;
			text-align: center;
			&:hover {
				background-color: #f2f2f2
			}
		}
		.active {
			background-color: $admin-color-secondary !important;
			color: #fff;
		}
	}
	.section {
		margin-bottom: 30px;
		h4 {
			font-size: 1.3em;
			margin-bottom: 15px;
		}
		.desc {
			margin-bottom: 15px;
		}
		input {
			width: 100%;
			padding: 8px;
			border: 1px solid #d3d3d3;
		}
	}
	.section:last-child {
		margin-bottom: 0px;
	}
}

// =========================================/
// Bottom Buttons
// =========================================/

.buttons-bottom {
	@include flex-container(space-between,center);
	margin-top: 50px;
	.save {
		margin: 0px 15px 0px 0px;
		padding: 8px;
		cursor: pointer;
		background-color: $admin-color-secondary;
		@include transition;
		border-radius: 5px;
		min-width: 80px;
		text-align: center;
		color: $color-white;
		&:hover {
			background-color: darken($admin-color-secondary,10%);
		}
	}
	.save.save-close {
		background-color: complement($admin-color-secondary);
		&:hover {
			background-color: darken(complement($admin-color-secondary),10%);
		}
	}
	.save.delete {
		background-color: $admin-color-primary;
		&:hover {
			background-color: darken($admin-color-primary,10%);
		}
	}
	.save.cancel {
		background-color: #a9a9a9;
		&:hover {
			background-color: darken(#a9a9a9,10%);
		}
	}
	.save:last-child {
		margin-right: 0px;
	}
	div {
		@include flex-container;
	}
}

// =========================================/
// Form 
// =========================================/

.form-details {
	@include flex-container(space-between,flex-start);
	margin-top: 30px;
	.detail {
		width: 49%;
		margin-bottom: 20px;
		h2 {
			font-size: 1.3em;
			font-weight: 500;
			margin-bottom: .5em;
		}
		p {

		}
		input {
			width: 100%;
			padding: 8px;
			border: 1px solid #d3d3d3;
		}
	}
}
.form-groups {
	.group {
		@include flex-container(space-between,center);
		padding: 0px 30px;
		position: relative;
		margin-bottom: 20px;
		.lines {
			position: absolute;
			color: #d3d3d3;
			cursor: move;
			left: 0;
	    font-size: 1.5em;
	    top: 26px;
			&:hover {
				color: gray;
			}
		}
		.delete {
			font-size: 1.5em;
			top: 23px;
			color: #d3d3d3;
			position: absolute;
			padding: 0px 5px;
			cursor: pointer;
			right: 0;
			&:hover {
				color: gray;
			}
		}
		.input {
			background-color: $color-white;
			padding: 20px;
			@include flex-container(flex-start,center);
			border-radius: 5px;
			box-shadow: 2px 2px 2px #d3d3d3;
			position: relative;
			cursor: move;
			p {
				margin: 0px 10px 0px 0px;
		    width: 75px;
		    background-color: #333;
		    color: #fff;
		    border-radius: 3px;
		    font-size: .8em;
		    text-align: center;
			}
			label {
				font-weight: 400;
				margin-right: 5px;
				font-size: 1em;
			}
			input {
				font-size: .9em;
				padding: 5px;
				max-width: 20%;
				margin-right: 10px;
			}
			.required-input {
				margin-right: 0px;
			}
		}
	}
	.group-submit {
		.input {
			cursor: default;
		}
	}
}
.form-buttons {
	@include flex-container(flex-start,center);
	margin-top: 50px;
	p {
		margin: 0px 20px 0px 0px;
		font-weight: 600;
		padding-left: 20px;
	}
	.button {
		padding: 10px;
		cursor: pointer;
		border: 2px dashed #d3d3d3;
		width: 150px;
		border-radius: 5px;
		text-align: center;
		background-color: $color-white;
		margin-right: 15px;
		font-weight: 400;
		&:hover {
			border: 2px solid #d3d3d3;
		}
	}
}

// =========================================/
// Content
// =========================================/

.page-admin {
	.admin-content {
		width: 100%;
		.atbs-header {
			h2 {
				font-size: 2em;
				font-weight: 600;
				margin: 0px 0px 10px 30px;
			}
			.url-header {
				margin: 0px 0px 20px 30px;
				padding: 5px;
				font-size: .85em;
				border-radius: 3px;
				border: 1px solid #d3d3d3;
				background-color: #f9f9f9;
				width: 100%;
				max-width: 400px;
			}
		}
		.ac-block {
			position: relative;
			width: 100%;
			.fa-times {
				position: absolute;
				top: 0;
				right: 0;
				font-size: 1.3em;
				padding: 10px;
				color: #a9a9a9;
				cursor: pointer;
				&:hover {
					color: #333;
				}
			}
			h2 {
				margin-bottom: .5em;
			}
			p {
				font-size: .9em;
				line-height: 1.5em;
				margin-bottom: 1em;
			}
			input {
				width: 100%;
				padding: 8px;
				border: 1px solid #d3d3d3;
			}
			input.uneditable {
				background-color: #f9f9f9;
			}
			.dates-editor {
				@include flex-container;
				input {
					width: 49%;
				}
			}
			.select-images {
				@include transition;
				padding: 5px 15px;
				cursor: pointer;
				background-color: $color-primary;
				color: $color-white;
				border: 0px;
				border-radius: 3px;
				display: inline-block;
				margin: 10px 10px 0px 0px;
				&:hover {
					background-color: darken($color-primary, 5%);
				}
			}
			table {
				width: 100%;
		    margin: 30px 0px 0px;
		    border-radius: 0px;
		    box-shadow: none;
		    table-layout: fixed;
		    tr {
		    	border-top: 1px solid #d3d3d3;
		    	border-bottom: 1px solid #d3d3d3;
		    	border-left: 1px solid #d3d3d3;
		    }
		    td {
		    	background: none;
		    	color: #333;
		    	border-right: 1px solid #d3d3d3;
		    	padding: 10px;
		    }
			}
			.table-resize {
				@include flex-container(flex-start,center);
				margin-top: 20px;
				input {
					width: 40px;
					padding: 4px 8px;
				}
				span {
					font-weight: 600;
					margin: 0px 10px;
				}
				p {
					background-color: gray;
					color: #fff;
					padding: 5px 10px;
					border-radius: 3px;
					margin: 0px 0px 0px 10px;
					&:hover {
						background-color: #a9a9a9;
						cursor: pointer;
					}
				}
			}
		}
		.buttons {
			@include flex-container(space-between, center);
			margin-top: 10px;
			input[type=submit] {
				@include transition;
				padding: 10px 20px;
				cursor: pointer;
				background-color: darken($color-secondary,20%);
				color: $color-white;
				border: 0px;
				border-radius: 3px;
				display: inline-block;
				font-size: 1em;
				line-height: 1em;
				&:hover {
					background-color: darken($color-secondary, 15%);
				}
			}
			.delete {
				padding: 10px 20px;
				font-size: 1em;
				line-height: 1em;
				background-color: #a9a9a9;
				color: #fff;
				border-radius: 3px;
				margin: 0px;
				cursor: pointer;
				&:hover {
					background-color: #333;
				}
			}
		}
		.need-to-save {
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			@include flex-container(center,center);
			background-color: rgba(0,0,0,.5);
			z-index: 1;
			p {
				color: #fff;
				font-size: 2em;
				width: 95%;
				text-align: center;
				max-width: 800px;
				line-height: 1.5em;
			}
		}
	}
}

// =========================================/
// Content - New Section
// =========================================/

.page-admin {
	.admin-content {
		.ac-block {
			.acb-new-section {
				@include flex-container;
				input[type='text'], input[type='number'] {
					width: 49%;
				}
				div {
					width: 100%;
					@include flex-container;
				}
				select {
					width: 49%;
					height: 40px;
					margin: 12px 0px;
					border: 1px solid #d3d3d3;
				}
				.add-section-submit {
					display: inline-block;
					width: max-content;
					background-color: $color-primary;
					transition: all 0.1s;
					padding: 10px 20px;
					cursor: pointer;
					color: #fff;
					border: 0px;
					border-radius: 3px;
					display: inline-block;
					margin: 10px 10px 0px 0px;
					&:hover {
						background-color: darken($color-primary, 5%);
					}
				}
				.list-selected {
					display: block;
					.list-sel {
						position: relative;
						padding: 10px 30px 10px 10px;
						border: 1px solid #d3d3d3;
						width: 500px;
						@include flex-container(space-between,center);
						margin-bottom: 5px;
						p {
							margin: 0px;
						}
						input {
							font-size: .9em;
							padding: 5px;
							width: 44%;
						}
						i {
							cursor: pointer;
							margin-top: 5px;
						}
					}
				}
				.list-options {
					@include flex-container(flex-start,center);
					margin-top: 10px;
					p {
						padding: 8px 20px;
						margin-right: 5px;
						border-radius: 3px;
						background-color: #d3d3d3;
						cursor: pointer;
						&:hover {
							background-color: #c8c8c8;
						}
					}
				}
			}
		}
	}
}

// =========================================/
// Content - Quill Overrides
// =========================================/

.page-admin {
	.admin-content {
		.ac-block {
			.ql-container {
				font-size: inherit;
			}
			.ql-editor {
				padding: 25px 15px 10px;
				.ql-tooltip {
					input {
						width: auto;
					}
				}
				p {
					font-size: .9em;
					line-height: 1.5em;
					margin-bottom: 1em;
				}
				li {
					font-size: .9em;
					line-height: 1.5em;
				}
				h1 {
					font-size: 2.2em;
					line-height: 1.3em;
					margin-bottom: .8em;
					font-weight: 600;
				}
				h2 {
					font-size: 1.8em;
					line-height: 1.3em;
					margin-bottom: .8em;
					font-weight: 600;
				}
				h3 {
					font-size: 1.6em;
					line-height: 1.3em;
					margin-bottom: .8em;
					font-weight: 600;
				}
				h4 {
					font-size: 1.4em;
					line-height: 1.3em;
					margin-bottom: .8em;
					font-weight: 600;
				}
				h5 {
					font-size: 1.2em;
					line-height: 1.3em;
					margin-bottom: .8em;
					font-weight: 600;
				}
				h6 {
					font-size: 1em;
					line-height: 1.3em;
					margin-bottom: .8em;
					font-weight: 600;
				}
				ul,ol {
					padding-left: .5em;
				}
				strong {
					font-weight: 600;
				}
				em {
					font-style: italic;
				}
				sup {
					vertical-align: super;
					font-size: .7em;
					line-height: 1em;
				}
				sub {
					vertical-align: sub;
					font-size: .7em;
					line-height: 1em;
				}
			}
		}
	}
}

// =========================================/
// Footer
// =========================================/

.footer-admin {
	background-color: $admin-color-bg-light;
	padding: 30px 0px;
	display: none;
	.fa-container {
		@include global-width;
		a {
			color: $admin-color-primary;
			&:hover {
				color: $admin-color-secondary;
			}
			i {
				padding-right: 10px;
			}
		}
	}
}

// =========================================/
// Listing
// =========================================/

.admin-content-listing {
	@include flex-container;
	.acl-header {
		width: 100%;
		margin-bottom: 30px;
		a {
			display: inline-block;
			border: 2px solid #d3d3d3;
			padding: 8px 25px;
			color: #333;
			border-radius: 3px;
			text-decoration: none;
			&:hover {
				border: 2px solid #333;
				background-color: #333;
				color: #fff;
			}
		}
	}
	.atb-single {
		width: 32%;
		@include admin-white-background;
		margin: 0px 0px 20px;
		color: $color-black !important;
		text-decoration: none !important;
		@include transition(.2s);
		@include flex-container(space-between,center);
		&:hover {
			box-shadow: 0px 0px 10px #a9a9a9;
		}
		h1,h2 {
			margin: 0px;
			font-size: 24px;
			line-height: 30px;
		}
		p {
			font-size: 14px;
			font-weight: 400;
			margin: 0px;
			font-style: italic;
			color: gray;
			width: 100%;
			margin-top: 10px;
		}
		a {
			display: inline-block;
			border: 2px solid #d3d3d3;
			padding: 5px 15px;
			border-radius: 3px;
			color: #333;
			&:hover {
				background-color: #333;
				color: #fff;
			}
		}
	}
}
.atbs-header .atbsh-links a,
.admin-block-container .cancel-link,
.admin-block-container input[type=submit] {
  margin: 0px;
  border: 2px solid $admin-color-bg-medium;
  padding: 5px 15px;
  border-radius: 3px;
  color: $admin-color-black;
  line-height: 16px;
  margin-left: 3px;
  background: none;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
}
.admin-block-container {
	.cancel-link {
		font-family: $admin-body-font;
	}
	.acul-delete {
		color: #333;
		font-size: 1em;
		&:hover {
			background-color: $admin-color-primary;
			border: 2px solid $admin-color-primary;
			color: $color-white;
		}
	}
}
.atbs-header .atbsh-links a:hover,
.admin-block-container .cancel-link:hover,
.admin-block-container input[type=submit]:hover {
	cursor: pointer;
	background-color: $admin-color-bg;
	border: 2px solid $admin-color-bg;
	color: $admin-color-white;
}

// =========================================/
// Vertical Listing
// =========================================/

.admin-vert-list {
	.avl-post {
		@include admin-white-background;
		display: none;
		h3 {
			font-size: 1.2em;
			font-weight: 600;
			margin-bottom: 15px;
		}
		p:first-child {
			font-weight: 600;
		}
		em {
			font-style: italic;
		}
		p:last-child {
			margin: 0px;
		}
	}
	.avlp-active {
		display: block;
	}
	.avl-admin-post {
		display: block;
		p {
			font-weight: 400 !important;
		}
		div {
			p {
				cursor: pointer;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

// =========================================/
// Pages
// =========================================/

.admin-block-container {
	.atbs-header {
		margin-bottom: 20px;
		h2 {
			font-size: 1.5em;
			font-weight: 900;
			span {
				padding-left: 10px;
				font-size: .8em;
				font-style: italic;
				font-weight: 500;
			}
		}
	}
	.table-editor {
		border: 1px solid #d3d3d3;
		border-bottom: 0px;
		width: 100%;
		overflow-x: scroll;
		table {
			margin: 0px;
			border: 1px solid #d3d3d3;
			border-bottom: 0px;
			border-right: 0px;
			border-radius: 0px;
			width: 100%;
		}
		tr {
			display: flex;
			margin-top: -2px;
		}
		td {
			background-color: inherit !important;
			border: inherit !important;
			padding: 0px !important;
			margin-left: -2px;
			flex: 1;
			min-width: 200px;
			min-height: 100px;
		}
		textarea {
			border: 1px solid #d3d3d3;
			display: block;
			font-size: 14px;
			padding: 12px;
			width: 100%;
			height: 100%;
		}
	}
}
.table-actions span {
	display: inline-block;
  margin: 10px 10px 0px 10px;
  font-size: .9em;
  color: $admin-color-primary;
  cursor: pointer;
  &:hover {
  	text-decoration: underline;
  }
  i {
  	padding-right: 5px;
  }
}
.ac-block {
	@include admin-white-background;
	h2 {
		font-size: 1.2em;
		font-weight: 900;
		p {
			margin: 10px 0px 20px;
			font-size: 13px;
			font-style: italic;
			font-weight: 400;
		}
	}
	.atbs-editor {
		width: 100%;
		.ql-snow {
			.ql-tooltip.ql-editing input[type=text] {
				width: unset;
			}
		}
	}
	.textarea-inline {
		width: 100%;
    border: 1px solid #a9a9a9;
    height: 80px;
    padding: 15px;
    font-size: .9em;
	}
	.input-inline {
		display: inline-block;
		font-size: .9em;
	}
	.date {
		margin-bottom: 10px;
		span {
			display: inline-block;
			font-weight: 900;
			margin: 0px 10px;
		}
		input {
			padding: 5px;
		}
	}
}
.atbs-footer {
	@include flex-container(flex-end);
	padding: 10px 0px;
}
.atbs-footer form {
	display: inline-block;
}
.ac-block-active {
	.ql-toolbar {
		position: fixed;
		top: 0;
		background-color: #fff;
		z-index: 1;
	}
	.ql-container {
		padding-top: 42px;
	}
}

// =========================================/
// Images
// =========================================/

.acb-image,
.acb-images {
	position: relative;
	.acbi-active {
		height: 250px;
		width: 250px;
		background-size: 90%;
		background-position: center;
		background-repeat: no-repeat;
		background-color: #f2f2f2;
		border-radius: 5px;
		border: 2px solid #f2f2f2;
		position: relative;
		&:hover {
			cursor: pointer;
			border: 2px solid gray;
		}
		i {
			position: absolute;
			top: 0;
			right: 0;
			color: $admin-color-primary;
			cursor: pointer;
			&:hover {
				color: $admin-color-secondary;
			}
		}
	}
	.acbi-container {
		flex-wrap: wrap;
		justify-content: space-between;
		display: none;
		.image-container {
			@include flex-container;
			width: 100%;
			.acbic-single {
				width: 24%;
				height: 150px;
				background-size: 90%;
				background-position: center;
				background-repeat: no-repeat;
				background-color: #f2f2f2;
				border-radius: 5px;
				margin: 5px 0px;
				border: 2px solid #f2f2f2;
				position: relative;
				&:hover {
					cursor: pointer;
					border: 2px solid gray;
				}
			}
		}
	}
	.acbi-container-show {
		display: flex;
	}
}
.acbic-new {
	width: 100%;
	height: 100px;
	background-color: #f2f2f2;
	border-radius: 5px;
	margin: 10px 0px;
	border: 2px dashed $admin-color-bg-medium;
	cursor: pointer;
	@include flex-container(center, center);
	i {
		color: #a9a9a9;
		font-size: 28px;
	}
	p {
		margin: 0px !important;
	}
	.dropzone {
		height: 100%;
		@include flex-container(center, center);
		cursor: pointer;
		i {
			margin-top: 40px;
		  font-size: 30px;
		  color: $admin-color-bg-medium;
		}
		.dz-default {
			font-size: 13px;
		  width: 100%;
		  text-align: center;
		  margin-bottom: 30px;
		  color: gray;
		}
	}
	.dz-preview {
		display: none;
	}
}

// =========================================/
// Images Listing - (like for galleries)
// =========================================/

.acb-images {
	.acb-images-current {
		.select-image {
			cursor: pointer;
			background-color: $color-primary;
			color: #fff;
			border-radius: 5px;
			padding: 5px 15px;
			display: inline-block;
		}
		.acbi-active {
			height: 150px;
			width: 24%;
			display: inline-block;
			margin: 5px .5%;
			position: relative;
			i {
				position: absolute;
				top: 0;
				right: 0;
				color: $admin-color-primary;
				cursor: pointer;
				&:hover {
					color: $admin-color-secondary;
				}
			}
		}
	}
}

// =========================================/
// Sign out
// =========================================/

.admin-container .ac-signout {
	font-size: 18px;
	width: 100%;
	text-align: center;
	padding: 100px 0px;
}

// =========================================/
// Events
// =========================================/

.acbi-multiple {
	display: flex;
}
.acbi-multiple label {
	margin-right: 10px;
	font-size: 14px;
	width: 33%;
}
.acbi-multiple input {
	padding: 8px;
  margin-top: 5px;
  width: 100%;
}
.admin-content {
	.ac-new-event {
		@include transition;
		width: 200px;
		border: 2px solid $admin-color-bg-medium;
		padding: 15px;
		display: inline-block;
		text-align: center;
		color: $admin-color-black;
		border-radius: 3px;
		font-size: 1.1em;
		cursor: pointer;
		background-color: $admin-color-white;
		font-weight: 500;
		margin-bottom: 20px;
	}
}
.admin-content .ac-new-event:nth-child(2) {
	margin-left: 20px;
}
.admin-content .ac-new-event i {
	padding-left: 10px;
}
.admin-content .ac-new-event:hover {
	background-color: $admin-color-black;
	border: 2px solid $admin-color-black;
	color: $admin-color-white;
}
.ac-update-links {
	@include flex-container;
}
.ac-update-links a {
	margin: 0px;
  border: 2px solid $admin-color-bg-medium;
  padding: 5px 15px;
  border-radius: 3px;
  color: $admin-color-black;
  line-height: 16px;
  margin-left: 3px;
  background: none;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
}
.ac-update-links a:hover {
	background-color: $admin-color-black;
	border: 2px solid $admin-color-black;
	color: $admin-color-white;
}
.ac-update-links span {
	font-size: .8em;
	margin-left: 20px;
	color: gray;
}

// =========================================/
// Meta
// =========================================/

.ac-block-image-auto textarea {
	display: none;
}
.input-count {
	margin: 0px;
	font-size: 12px;
	text-align: right;
	display: none;
}

// =========================================/
// Media
// =========================================/

.admin-media-container {
	.image-container {
		@include flex-container;
		.acbic-single {
			width: 24%;
			height: 200px;
			margin: 10px 0px;
			position: relative;
			background-position: center;
			background-size: 90%;
			background-repeat: no-repeat;
			border: 2px solid #f2f2f2;
			cursor: pointer;
			border-radius: 3px;
			&:hover {
				border: 2px solid $admin-color-secondary;
			}
			.copy-area {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
			.delete {
				position: absolute;
				top: 5px;
				right: 5px;
				z-index: 1;
				&:hover {
					color: $admin-color-primary;
				}
			}
			.media-file-name {
				@include overlay;
				@include flex-container(center,center);
				margin: 0px;
				font-weight: 600;
			}
		}
	}
	.image-copied-confirmation {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		@include flex-container(center,center);
		background-color: rgba(0,0,0,.6);
		@include transition(0.5s);
		visibility: hidden;
		opacity: 0;
		z-index: 5;
		p {
			color: #fff;
			font-size: 2em;
			margin: 0px;
		}
	}
	.image-copied-confirmation-active {
		visibility: visible;
		opacity: 1;
	}
}

// =========================================/
// Checkbox
// =========================================/

.checkbox-container {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  font-weight: 400;
  @include flex-container(normal, center);
  margin: 0px;
  height: 25px;
  input {
	  position: absolute;
	  opacity: 0;
	  cursor: pointer;
	  height: 0;
	  width: 0;
	  &:checked ~ .checkmark {
		  background-color: $admin-color-secondary;
		  &:after {
			  display: block;
			}
		}
	}
	.checkmark {
	  position: absolute;
	  top: 0;
	  left: 0;
	  height: 25px;
	  width: 25px;
	  background-color: #d3d3d3;
	  &:after {
		  content: "";
		  position: absolute;
		  display: none;
		  left: 9px;
		  top: 5px;
		  width: 5px;
		  height: 10px;
		  border: solid white;
		  border-width: 0 3px 3px 0;
		  -webkit-transform: rotate(45deg);
		  -ms-transform: rotate(45deg);
		  transform: rotate(45deg);
		}
	}
	&:hover input ~ .checkmark {
	  background-color: #ccc;
	}
}

// =========================================/
// Blog
// =========================================/

.hide-textarea {
	display: none;
}

// =========================================/
// Alerts
// =========================================/

.ac-block-input {
	select {
		display: block;
		font-weight: 300;
		font-size: 14px;
		height: 40px;
		font-family: $admin-body-font;
		width: 33%;
		background-color: $admin-color-white;
		border: 1px solid $admin-color-bg-medium;
	}
	input[type=color] {
		padding: 0px;
		border: 1px solid $admin-color-bg-medium;
		height: 30px;
	}
}

// =========================================/
// Ordering
// =========================================/

.atb-order-container {
	background-color: #fff;
  box-shadow: 1px 1px 1px #d3d3d3;
  border-radius: 5px;
  padding: 30px;
  margin: 0px auto 20px auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  .atb-order-single {
  	@include flex-container(space-between, center);
  	width: 100%;
  	padding: 15px 0px;
  	border-bottom: 1px solid #d3d3d3;
  	p {
  		margin: 0px;
  	}
  	i {
  		cursor: pointer;
  		font-size: 1.5em;
  		margin-right: 20px;
  	}
  }
  .atb-order-single:last-child {
  	border-bottom: 0px;
  }
}

// =========================================/
// Super
// =========================================/

.admin-super-container {
	@include admin-white-background;
	h1 {
		width: 100%;
	  font-weight: 900;
	  font-size: 18px;
	}
	label {
		display: block;
		font-size: 1.1em;
		font-weight: 900;
		margin-bottom: 30px;
		input {
			display: block !important;
			width: 100%;
		  padding: 8px;
		  border: 1px solid $admin-color-bg-medium;
		  margin: 5px 0px 10px;
		  font-size: .9em;
		}
		select {
			display: block;
			width: 100%;
		  height: 37px;
		  border: 1px solid $admin-color-bg-medium;
		  margin: 5px 0px 10px;
		  font-size: .9em;
		}
	}
	input[type=submit] {
		@include transition;
		width: 150px;
		background-color: $admin-color-secondary;
		color: $admin-color-white;
		border: 0px !important;
		border-radius: 3px;
		display: inline-block;
		margin: 30px 0px 0px;
		&:hover {
			background-color: $admin-color-secondary-dark;
		}
	}
	.super-add {
		font-weight: 900;
		cursor: pointer;
		&:hover {
			color: $admin-color-primary;
		}
	}
	.asc-multiple {
		@include flex-container;
		label {
			width: 49%;
			margin-bottom: 0px;
			input {
				width: 100%;
			}
		}
	}
}

// =========================================/
// Select
// =========================================/

.ac-block {
	.acb-select {
		select {
			width: 100%;
			height: 40px;
		}
	}
}

// =========================================/
// Color
// =========================================/

.ac-block {
	.acb-color {
		input {
			width: 100%;
			height: 50px;
		}
	}
}

// =========================================/
// Blocks
// =========================================/

.ac-block-blocks {
	.sections {
		.section {
			@include flex-container(space-between,center);
			margin-bottom: 20px;
			position: relative;
			.lines {
				font-size: 1.5em;
				cursor: move;
				width: 60px;
				text-align: center;
			}
			.section-content {
				width: calc(100% - 60px);
				border: 2px solid;
				border-radius: 5px;
				padding: 30px;
				.item {
					margin-bottom: 15px;
					h2 {
						font-size: 1em;
					}
					.acb-image {
						.acbi-active {
							width: 200px;
							height: 200px;
						}
					}
				}
			}
		}
	}
	.add {
		cursor: pointer;
		margin: 20px 0px 0px 60px;
		i {
			margin-right: 5px;
		}
	}
}