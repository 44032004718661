// =========================================/
// Queries
// =========================================/

.admin-vert-list {
	.avl-post-header {
		background-color: #fff;
    box-shadow: 1px 1px 1px #d3d3d3;
    border-radius: 5px;
    padding: 30px;
    margin: 0px auto 20px auto;
    div {
    	@include flex-container(space-between,center);
    	p, a {
				font-size: 1.2em;
				margin: 0px;
				line-height: 1.3em;
    	}
    }
	}
	.avl-post {
		.query {
			.query-header {
				margin-bottom: 10px;
			}
			.query-fields {
				@include flex-container(flex-start);
				p {
					font-weight: 600;
					margin: 0px;
					padding: 15px 30px;
					border: 1px solid #d3d3d3;
					margin-left: -1px;
					span {
						font-weight: 400;
						margin-left: 10px;
					}
					margin-top: -1px;
				}
			}
		}
	}
}