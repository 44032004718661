// =========================================/
// Resources
// =========================================/

.avl-tabs {
	@include flex-container(center);
  width: 100%;
  border-bottom: 1px solid $admin-color-bg-medium;
  p,a {
		margin: 0px 0px -1px;
		padding: 10px 20px;
		border-radius: 5px 5px 0px 0px;
		background-color: #f2f2f2;
		border-right: 1px solid $admin-color-bg-medium !important;
		border-bottom: 1px solid $admin-color-bg-medium !important;
		cursor: pointer;
    color: #333 !important;
    font-size: 14px;
    line-height: 22px;
    font-family: $admin-body-font;
    text-decoration: none !important;
	}
	.avct-active {
	  border: 1px solid #f2f2f2;
	  background-color: #fff;
	}
}
.avl-content {
	display: none;
	@include admin-white-background;
}
.avlc-active {
	display: block;
}
.avlc-post {
	margin: 20px 0px;
	border-bottom: 1px dashed #d3d3d3;
	padding-bottom: 8px;
	h3 {
		font-weight: 900;
		font-size: 1.1em;
		font-family: $admin-body-font;
		margin-bottom: 20px;
	}
	p {
		font-size: .8em;
		line-height: 1.5em;
		margin-bottom: 1em;
	}
	ul {
		list-style: disc;
		margin: 0px 0px 1em 20px;
		li {
			font-size: .8em;
			line-height: 1.5em;
		}
	}
	strong {
		font-weight: 600;
	}
}