// =========================================/
// Admins
// =========================================/

.admin-admins-container {
	@include admin-white-background;
	@include flex-container(space-between, center);
	h2 {
		width: 100%;
	  font-weight: 900;
	  font-size: 18px;
	  @include flex-container;
	}
	.admin {
		width: 100%;
	  border-top: 1px solid #f2f2f2;
	  padding-top: 20px;
	  margin-top: 20px;
	  @include flex-container;
	  h3 {
			font-weight: 900;
			margin-bottom: 15px;
			width: 100%;
		}
		p {
			margin-bottom: 0px;
			font-weight: 900;
			span {
				font-weight: 400;
				padding-left: 5px;
			}
		}
		div {
			width: 150px;
			p {
				font-weight: 500;
				@include transition;
				@include admin-button(130px, 0px 0px 10px);
			}
			.aac-deny:hover {
				background-color: $admin-color-primary;
				border: 2px solid $admin-color-primary;
			}
			.aac-remove:hover {
				background-color: $admin-color-primary;
				border: 2px solid $admin-color-primary;
			}
		}
	}
}