// =========================================/
// Shop - Orders
// =========================================/

.orders {
	.order {
		background-color: #fff;
		border-bottom: 1px solid #d3d3d3;
		position: relative;
		width: 100%;
		.top {
			@include flex-container(space-between,center);
			cursor: pointer;
			padding: 20px;
			&:hover {
				background-color: #f2f2f2;
			}
			.details {
				@include flex-container(flex-start,center);
				width: calc(100% - 30px);
				p {
					margin: 0px 15px 0px 0px;
				}
			}
			.status {
				width: 30px;
				svg {
					width: 30px;
				}
				.warn {
					fill: red;
					display: none;
				}
				.done {
					fill: green;
					display: none;
				}
			}
			.status.pending {
				.warn {
					display: block;
				}
			}
			.status.complete {
				.done {
					display: block;
				}
			}
		}
		.main {
			@include flex-container;
			display: none;
			padding: 20px;
			p {
				font-weight: 600;
				span {
					font-weight: 400;
				}
			}
			.person {
				width: 49%;
			}
			.product {
				width: 49%;
				.item {
					border-bottom: 1px solid #f2f2f2;
					margin-bottom: 1em;
					p ~ p {
						font-weight: 400;
					}
				}
			}
		}
	}
	.order.active {
		border-bottom: 2px solid #333;
		.top {
			border-bottom: 1px solid #f2f2f2;
		}
		.main {
			display: flex;
		}
	}
}